import React, { ReactElement } from 'react'

// eslint-disable-next-line import/no-extraneous-dependencies
import { Router } from '@reach/router' // included with gatsby

import { AppComponent } from '../../components/app/app.component'
import CustomerDetailComponent from '../../components/customer/customer-detail.component'
import LoginGuardComponent from '../../components/app/login.gaurd.component'
import RouterComponent from '../../components/app/app-router.component'


export default function CustomerDetailPage(): ReactElement {
  return (
    <AppComponent>
      <LoginGuardComponent>
        <Router primary={false} component={RouterComponent}>
          <CustomerDetailComponent path="/customers/:customerId" />
        </Router>
      </LoginGuardComponent>
    </AppComponent>
  )
}
