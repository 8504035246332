import React, { ReactElement, useEffect, useState, useContext } from 'react'
import moment from 'moment'

import { CircularProgress, makeStyles, Typography, List, ListItem, ListItemText } from '@material-ui/core'
// eslint-disable-next-line import/no-extraneous-dependencies
import { RouteComponentProps, navigate } from '@reach/router' // imported with Gatsby
import clsx from 'clsx'
import ArrowBackIcon from '@material-ui/icons/ArrowBackIos'
import { getCustomer } from '../../services/customer.service'
import { Customer } from '../../models/customer'
import { colors } from '../../styles/colors'
import { MessageContext } from '../../contexts/message.store'
import { Payment } from '../../models/payment'

export type CustomerComponentProps = {
  customerId?: string
} & RouteComponentProps

const useStyles = makeStyles((theme) => ({
  progressInvisible: {
    visibility: 'hidden',
  },
  totalSection: {
    display: 'flex',
    justifyContent: 'space-between',
    marginTop: theme.spacing(3),
    marginBottom: theme.spacing(3),
    padding: theme.spacing(2),
    borderRadius: '8px',
    backgroundColor: colors.backgroundBlue,
  },
  customersSection: {
    marginTop: theme.spacing(3),
  },
  customerWrapper: {
    display: 'flex',
    flex: 1,
    flexDirection: 'column',
    justifyContent: 'space-between',
  },
  detailWrapper: {
    width: '100%',
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
    marginRight: theme.spacing(3),
  },
  titleWrapper: {
    width: '100%',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
  },
  componentWrapper: {
    width: '100%',
    maxWidth: '800px',
    display: 'flex',
    justifyContent: 'center',
    [theme.breakpoints.up('md')]: {
      marginLeft: theme.spacing(5),
    },
  },
  backArrow: {
    marginTop: '5px',
    marginRight: theme.spacing(1),
    cursor: 'pointer',
  },
  amountText: {
    color: theme.palette.primary.main,
  },
  paymentStyle: {
    borderRadius: '5px',
    '&:nth-child(odd)': {
      background: theme.palette.action.hover,
    },
  },
}))
export default function CustomerDetailComponent({ customerId }: CustomerComponentProps): ReactElement {
  const classes = useStyles()
  const [, messageDispatch] = useContext(MessageContext)
  const [isLoading, setLoading] = useState(false)
  const [customer, setCustomer] = useState<Customer>()
  const getCustomerFromApi = async (): Promise<void> => {
    setLoading(true)
    try {
      setCustomer(await getCustomer(+customerId))
    } catch (err) {
      messageDispatch({ type: 'ERROR', message: `${err.status}` })
    }
    setLoading(false)
  }

  const goBack = (): void => {
    navigate(-1)
  }

  useEffect(() => {
    if (customerId) getCustomerFromApi()
    else setCustomer(null)
  }, [customerId])
  const detail = (): ReactElement => {
    const payments = (payment: Payment): ReactElement => {
      const date = moment(payment.payment_date, 'M-D-YYYY').format('MMM Do YYYY')
      const note = payment.notes ? ` - ${payment.notes}` : ''
      return (
        <List component="div" disablePadding className={classes.paymentStyle}>
          <ListItem button>
            <ListItemText
              primary={date}
              secondary={(
                <>
                  <Typography
                    component="span"
                    variant="body2"
                    color="textPrimary"
                  >
                    {`${payment.payment_type}`}
                  </Typography>
                  {note}
                </>
              )}
            />
            <span className={classes.amountText}>{`$${payment.amount}`}</span>
          </ListItem>
        </List>
      )
    }
    const total = customer?.payments.reduce((acc, payment) => acc + +payment.amount, 0)
    return (
      <div className={classes.componentWrapper}>
        {/* <CustomerDialog customerId={12743} handleCancel={() => console.log('CANCEL')} /> */}
        <ArrowBackIcon className={classes.backArrow} onClick={goBack} fontSize="small" />
        <div className={classes.detailWrapper}>
          <div className={classes.titleWrapper}>
            <Typography variant="h6" component="h6" style={{ fontWeight: 'bold' }}>{customer?.name}</Typography>
            <CircularProgress
              className={clsx({ [classes.progressInvisible]: !isLoading })}
            />
          </div>
          {customer && (
            <div className={classes.customerWrapper}>
              <section className={classes.customersSection}>
                {customer.payments.map(payments)}
              </section>
              <section className={classes.totalSection}>
                <Typography variant="h6" component="h6">Total Paid</Typography>
                <Typography variant="h6" component="h6" className={classes.amountText}>{`$${total.toFixed(2)}`}</Typography>
              </section>
            </div>
          )}
        </div>
      </div>
    )
  }
  return (
    <>
      {detail()}
    </>

  )
}
