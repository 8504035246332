import axios from '../utils/axios'
import { Customer } from '../models/customer'

export type PaymentFilter = {
  month: number
  year: number
}

export const getCustomer = async (id: number): Promise<Customer> => {
  const res: ApiResponse = await axios.get(`customers/${id}`)
  return res.data
}
