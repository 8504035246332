import { makeStyles } from '@material-ui/core'
import React, { ReactElement } from 'react'

const useStyles = makeStyles(() => ({
  customerWrapper: {
    height: '100%',
    display: 'flex',
    justifyContent: 'center',
  },
}))
export type RouterComponentPRops = {
  children?: React.ReactNode
}
export default function RouterComponent({ children }: RouterComponentPRops): ReactElement {
  const classes = useStyles()
  return <div className={classes.customerWrapper}>{children}</div>
}
